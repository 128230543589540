import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from 'grommet';

import SmartLink from '../SmartLink';
const CartShopCollectionButtons = ({ fontSize = '0.8rem', ...props }) => {
  return (
    <Box {...props}>
      <Box fill="horizontal">
        <SmartLink to={'/collections/supplies'}>
          <Button
            fill="horizontal"
            secondary
            label="Shop all Supplies"
            style={{ fontSize }}
          />
        </SmartLink>
      </Box>
      <Box fill="horizontal">
        <SmartLink to={'/products/paintershirt'} fill="horizontal">
          <Button
            fill="horizontal"
            style={{ fontSize }}
            secondary
            label="Shop all Apparel"
          />
        </SmartLink>
      </Box>
    </Box>
  );
};

CartShopCollectionButtons.propTypes = {
  fontSize: PropTypes.string,
};

export default CartShopCollectionButtons;
